import axios from "axios";
import { useState, useEffect } from "react";
import {
  useNavigate,
  Navigate,
  useSearchParams,
  Outlet,
  useLocation,
} from "react-router-dom";
import { Bounce, toast } from "react-toastify";
// export const baseUrl = "https://backend.albazsiab.com/eruoglass/api/";
// export const baseUrl = "https://backend.albazsiab.com/albaz/api/";
export const baseUrl = "https://backend.albazsiab.com/dev/api/";
export const fileUrl = "https://backend.albazsiab.com/dev/storage/";

export const checkToken = localStorage.getItem("token");

const notify = (message, delayTime) =>
  toast.error(message, {
    position: "top-center",
    delay: delayTime,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

//!=============================================================> post data login
export const useLOGIN = (initialState) => {
  const [formData, setFormData] = useState(initialState); // data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successfulLogin, setSuccessfulLogin] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (url) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend)
      .then((req) => {
        setLoading(false);
        setError("");
        localStorage.setItem("token", req.data.data.token);
        setSuccessfulLogin(true);
      })
      .catch((e) => {
        setSuccessfulLogin(false);
        setError(e.response?.data?.message);
        setLoading(false);
        if (Array.isArray(e?.response?.data?.message))
          e?.response?.data?.message?.map((m, i) => notify(m, i * 500));
        else notify(e?.response?.data?.message);
      });
  };
  //
  return {
    setFormData,
    formData,
    handleChange,
    loading,
    error,
    handleSubmit,
    successfulLogin,
    setSuccessfulLogin,
  };
};
// !======================================================================================
export const usePOST = (initialState) => {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewFile, setViewFile] = useState("");
  const [viewImages, setViewImages] = useState({});
  const [images, setImages] = useState({});
  const [checkArray, setCheckArray] = useState({});
  const [successfulPost, setSuccessfulPost] = useState(false);

  //=====================================================> function change   data all
  const handleChangeInput = (event) => {
    const { name, value, type, files, checked } = event.target;
    const newValue =
      type === "file" ? files[0] : type === "checkbox" ? checked : value;
    type === "file" && setViewFile(URL.createObjectURL(newValue));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue === true ? 1 : newValue === false ? 0 : newValue,
    }));
  };
  //=====================================================>  function change  array images
  const handleChangeArrayImages = (event) => {
    const { files, name } = event.target;
    setImages({ ...images, [name]: [files[0]] });
    if (images[name]) {
      setImages({
        ...images,
        [name]: [...images[name], files[0]],
      });
    }
    setViewImages({ ...viewImages, [name]: [URL.createObjectURL(files[0])] });
    if (images[name]) {
      setViewImages({
        ...viewImages,
        [name]: [...viewImages[name], URL.createObjectURL(files[0])],
      });
    }
  };
  //=====================================================> function change   array checkbox
  const handleCheckedArray = (e) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setCheckArray({ ...checkArray, [name]: [value] });
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: [...checkArray[name], value],
        });
      }
    } else {
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: checkArray[name].filter((p) => p !== value),
        });
      }
    }
  };

  //=====================================================> post data
  const handleSubmit = async (url) => {
    setSuccessfulPost(false);
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      if (value || key === "days") {
        formDataToSend.append(key, value);
      }
    }
    for (const [key, value] of Object.entries(checkArray)) {
      for (let i = 0; i < value.length; i++) {
        formDataToSend.append(key, value[i]);
      }
    }
    for (const [key, value] of Object.entries(images)) {
      for (let i = 0; i < value.length; i++) {
        formDataToSend.append(key, value[i]);
      }
    }

    axios
      .post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        setSuccessfulPost(true);
        setLoading(false);
        setError("");
        setData(req.data);
      })
      .catch((e) => {
        setSuccessfulPost(false);
        setError(e.response?.data?.message);
        setLoading(false);
        if (Array.isArray(e?.response?.data?.message))
          e?.response?.data?.message?.map((m, i) => notify(m, i * 500));
        else notify(e?.response?.data?.message);
      });
  };

  return {
    data,
    formData,
    setFormData,
    viewFile, //  لعرض الصورة او فيديو .....
    setViewFile, // ((local)) لحذف الصورة
    viewImages, // لعرض الصور
    setViewImages, // ((local)) لحذف الصور
    images, //api  لأرسال الصور الى
    setImages, //api  لحذف الصور الى
    error,
    setError,
    loading,
    setLoading,
    handleChangeInput,
    handleCheckedArray,
    handleChangeArrayImages,
    handleSubmit,
    successfulPost,
    setSuccessfulPost,
  };
};

//!=============================================================>start fetch data
export const useFETCH = (url, urlDelete) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isErroe, setIsError] = useState("");
  const [successfulDelete, setSuccessfulDelete] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    if (url) {
      axios
        .get(`${baseUrl}${url}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((req) => {
          if (req) {
            setData(req);
            setIsLoading(false);
            setIsError("");
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError("خطأ في جلب البيانات");
          notify("خطأ في جلب البيانات");

          if (e.request.status === 401) {
            navigate("/login");
            localStorage.clear();
          }
          if (e.request.status === 403) {
            navigate("/403");
          }
          if (e.request.status === 500) {
            navigate("/500");
          }
        });
    }
  }, [url]);

  const printClick = (urlPrint, exports) => {
    setIsLoading(true);
    axios({
      url: `${baseUrl}${url}${urlPrint}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setIsLoading(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setIsLoading(false);
      });
  };
  //?=============================================================> deleteItem
  const deleteItem = async (e) => {
    setSuccessfulDelete(false);
    setIsLoading(true);
    axios
      .delete(`${baseUrl}${urlDelete || url}/${e.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        setIsLoading(false);
        setIsError("تم الحذف  بنجاح");
        setSuccessfulDelete(true);
      })
      .catch((e) => {
        setSuccessfulDelete(false);
        setIsLoading(false);
        setIsError(" خطأ في الحذف  حاول مرة آخرى");
        notify("خطأ في الحذف، حاول مرة أخرى");
      });
  };

  return { data, isLoading, isErroe, deleteItem, printClick, successfulDelete };
};
//=============================================================> end fetch data

//!=============================================================> pagination or filter or search
export const useFilter = (params) => {
  const [filter, setFilter] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const handleParamsClick = (name, value) => {
    if (Array.isArray(value)) {
      if (value.length > 0) searchParams.set(name, value);
      else searchParams.delete(name);
    } else if (value) {
      searchParams.set(name, value);
    } else searchParams.delete(name);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };
  const handlePageClick = (event) => {
    setFilter(
      params || {
        page: event.selected + 1,
        sort_value: filter.get("sort_value") ? filter.get("sort_value") : "",
        sort_by: filter.get("sort_by") ? filter.get("sort_by") : "",
        state: filter.get("state") ? filter.get("state") : "",
        date: filter.get("date") ? filter.get("date") : "",
        start_date: filter.get("start_date") ? filter.get("start_date") : "",
        end_date: filter.get("end_date") ? filter.get("end_date") : "",
      }
    );
  };
  return {
    filter,
    handlePageClick,
    setFilter,
    handleParamsClick,
    searchParams,
  };
};
//!=============================================================> عدم دخول من غير تسجيل دخول
export const RequireAuth = () => {
  if (!localStorage.getItem("token")) {
    console.log(localStorage.getItem("token"));
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
//! =============================================================>عدم فتح صفحة تسجيل دخول اذا مسجل مسيقا
export const RequireLogin = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

//!=============================================================> تكبير الصورة
export const clickZoomInImage = (event) => {
  event.target.requestFullscreen();
};
