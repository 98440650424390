import React, { useEffect, useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import { usePOST } from "../APIs/useMyAPI";
import Loading from "./Loading/Loading";
import PopUpConfirm from "./PopUpConfirm";

const PopUpAddHours = ({ setIsOpen, id, ids, filterDate, setTemp,centerId }) => {
  let date = new Date();
  const { formData, loading, handleChangeInput, handleSubmit, successfulPost } =
    usePOST({
      main_hours: "8",
      extra_hours: "",
      cost_center_id:centerId,
      extra_hours_percentage: "150",
      worked_at: filterDate ? filterDate : date.toISOString().split("T")[0],
      ...(ids.length ? { worker_ids: ids } : {}),
    });

  const [showConfirmation, setShowConfirmation] = useState([{}, false]);

  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (ids.length) setShowConfirmation([formData, true]);
    else handleSubmit(`s-admin/section/workers/${id}/hours`, true);
  };

  let arrayWith8Eelements = [];
  for (let i = 0; i <= 8; i++) {
    arrayWith8Eelements.push(i);
  }

  useEffect(() => {
    if (successfulPost) {
      setIsOpen(["", false]);
      setTemp((prev) => prev + 1);
    }
  }, [successfulPost]);

  return (
    <div className="flex justify-center items-center w-full h-full absolute top-0 left-0">
      {loading && <Loading />}
      {showConfirmation[1] && (
        <PopUpConfirm
          setVisible={setShowConfirmation}
          formData={showConfirmation[0]}
          setTemp={setTemp}
        />
      )}
      <div
        className="bg-black/30 absolute w-full h-full top-0 left-0 z-10"
        onClick={() => setIsOpen(["", false])}
      />
      <div className="flex flex-col gap-4 bg-white rounded-lg border-gray-500 min-h-2/3 overflow-auto z-20 p-3">
        <IoCloseCircleSharp
          size={40}
          color="red"
          cursor="pointer"
          onClick={() => setIsOpen(["", false])}
        />
        <div className="flex gap-3 flex-col items-center">
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">عدد الساعات الأساسية:</h2>
            <div className="flex gap-2 justify-between flex-wrap">
              <input
                type="number"
                className="border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-4 text-black w-fit"
                name="main_hours"
                value={formData.main_hours}
                onChange={handleChangeInput}
              />
              <select
                name="main_hours"
                value={formData.main_hours}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {arrayWith8Eelements.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">عدد الساعات الإضافية:</h2>
            <div className="flex gap-2 justify-between flex-wrap">
              <input
                type="number"
                className="border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-2 text-black w-fit"
                name="extra_hours"
                value={formData.extra_hours}
                onChange={handleChangeInput}
              />
              <select
                name="extra_hours"
                value={formData.extra_hours}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {arrayWith8Eelements.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">
              كلفة الساعة الإضافية بالنسبة للكلفة الأساسية:
            </h2>
            <div className="flex gap-1  p-4 text-black w-fit">
              <div className="flex gap-2 items-center border border-t-0 border-l-0 border-r-0 border-b-blue-950">
                <input
                  type="number"
                  name="extra_hours_percentage"
                  value={formData.extra_hours_percentage}
                  onChange={handleChangeInput}
                />
                <p className="text-gray-400 text-lg">%</p>
              </div>
              <select
                name="extra_hours_percentage"
                value={formData.extra_hours_percentage}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {["0", "50", "75", "100", "150", "200"].map((e) => (
                  <option value={e}>{e}%</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">التاريخ:</h2>
            <input
              step={1}
              type="date"
              className=" border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-4 text-black w-fit"
              name="worked_at"
              value={formData.worked_at}
              onChange={handleChangeInput}
            />
          </div>

          <button
            onClick={handleSubmitMain}
            className="w-full p-2 text-lg font-semibold bg-gray-300 text-gray-700"
          >
            إضافة
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpAddHours;
