import React, { useEffect, useState } from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Sections = () => {
  const { filter } = useFilter();
  const [temp, setTemp] = useState(0);

  const { data, deleteItem, isLoading, successfulDelete } = useFETCH(
    `admin/sections${
      filter.get("page")
        ? "?page=" + filter.get("page") + `&temp=${temp}`
        : `?temp=${temp}`
    }`,
    "admin/sections"
  );
  const { printClick } = useFETCH(`admin/sections`);

  useEffect(() => {
    if (successfulDelete) setTemp((prev) => prev + 1);
  }, [successfulDelete]);

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="الأقسام" />
      <Table
        print={printClick}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        idDel={deleteItem}
        thead={["اسم القسم", "اسم المدير", "التكلفة الأدارية"]}
        tbody={["name", "admin", "administrative_hours_percentage"]}
        tdata={data?.data.data.data}
        links={[
          { name: "عرض التقارير", link: "/sections/report/" },
          { name: "عرض مراكز التكلفة", link: "/sections/cost-centers/" },
          { name: "عرض العمال ", link: "/workers?section_id=" },
          { name: "تعديل", link: "/sections/" },
        ]}
      />
      <Add link="/sections/add" />
    </div>
  );
};

export default Sections;
