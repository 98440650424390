import React, { useEffect, useState } from "react";
import { useFETCH, usePOST } from "../APIs/useMyAPI";
import Loading from "./Loading/Loading";
import { IoCloseCircleSharp } from "react-icons/io5";

const PopUpEditHours = ({ setIsOpen, id, setTemp }) => {
  const {
    setFormData,
    formData,
    loading,
    handleChangeInput,
    handleSubmit,
    successfulPost,
  } = usePOST({});

  // const [dateTime, setDateTime] = useState();

  const { data, isLoading } = useFETCH(
    `s-admin/section/cost-centers/workers/hours/${id}`
  );

  const dataOld = data?.data?.data;

  useEffect(() => {
    setFormData({
      main_hours: dataOld?.main_hours,
      extra_hours: dataOld?.extra_hours,
      extra_hours_percentage: dataOld?.extra_hours_percentage,
      worked_at: dataOld?.worked_at.split(" ")[0],
    });
  }, [dataOld]);

  const handleSubmitMain = () => {
    handleSubmit(`s-admin/section/cost-centers/workers/hours/${id}`, "");
  };

  let arrayWith8Eelements = [];
  for (let i = 0; i <= 8; i++) {
    arrayWith8Eelements.push(i);
  }

  useEffect(() => {
    if (successfulPost) {
      setIsOpen(["", false]);
      setTemp((prev) => prev + 1);
    }
  }, [successfulPost]);

  return (
    <div className="flex justify-center items-center w-full h-full absolute top-0 left-0">
      {isLoading || (loading && <Loading />)}
      <div
        className="bg-black/30 absolute w-full h-full top-0 left-0 z-10"
        onClick={() => setIsOpen(["", false])}
      />
      <div className="flex flex-col gap-4 bg-white rounded-lg border-gray-500 min-h-2/3 overflow-auto z-20 p-3">
        <IoCloseCircleSharp
          size={40}
          color="red"
          cursor="pointer"
          onClick={() => setIsOpen(["", false])}
        />
        <div className="flex gap-3 flex-col items-center">
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">عدد الساعات الأساسية:</h2>
            <div className="flex gap-2 justify-between flex-wrap">
              <input
                type="number"
                className="border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-4 text-black w-fit"
                name="main_hours"
                value={formData.main_hours}
                onChange={handleChangeInput}
              />
              <select
                name="main_hours"
                value={formData.main_hours}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {arrayWith8Eelements.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">عدد الساعات الإضافية:</h2>
            <div className="flex gap-2 justify-between flex-wrap">
              <input
                type="number"
                className="border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-2 text-black w-fit"
                name="extra_hours"
                value={formData.extra_hours}
                onChange={handleChangeInput}
              />
              <select
                name="extra_hours"
                value={formData.extra_hours}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {arrayWith8Eelements.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">
              كلفة الساعة الإضافية بالنسبة للكلفة الأساسية:
            </h2>
            <div className="flex gap-1  p-4 text-black w-fit">
              <div className="flex gap-2 items-center border border-t-0 border-l-0 border-r-0 border-b-blue-950">
                <input
                  type="number"
                  name="extra_hours_percentage"
                  value={formData.extra_hours_percentage}
                  onChange={handleChangeInput}
                />
                <p className="text-gray-400 text-lg">%</p>
              </div>
              <select
                name="extra_hours_percentage"
                value={formData.extra_hours_percentage}
                className="text-center py-3 px-5 w-fit"
                onChange={handleChangeInput}
              >
                {["50", "75", "100", "150", "200"].map((e) => (
                  <option value={e}>{e}%</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 justify-between w-full items-center max-sm:flex-col">
            <h2 className="font-semibold text-lg">التاريخ:</h2>
            <input
              type="date"
              className=" border border-t-0 border-l-0 border-r-0 border-b-blue-950 p-4 text-black w-fit"
              name="worked_at"
              onChange={handleChangeInput}
              value={formData.worked_at}
            />
          </div>
          <button
            onClick={handleSubmitMain}
            className="w-full p-2 text-lg font-semibold bg-gray-300 text-gray-700"
          >
            تعديل
          </button>
          <button
            onClick={() => setIsOpen(["", false])}
            className="w-full p-2 text-lg font-semibold bg-gray-300 text-gray-700"
          >
            إلغاء
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpEditHours;
