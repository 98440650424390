import React, { useState } from "react";

const Delete = ({ onClick, children }) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من حذف هذا العنصر ؟
              </p>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => {
                      onClick();
                      setSure(false);
                    }}
                  >
                    نعم
                  </div>
                </div>
                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {children ? (
        <div onClick={() => setSure(true)} className="w-fit">
          {children}
        </div>
      ) : (
        <div>
          <div
            onClick={() => setSure(true)}
            className="px-3 py-1 hover:bg-slate-400 w-full"
          >
            حذف
          </div>
        </div>
      )}
    </>
  );
};

export default Delete;
