import React from "react";
import {Add, Filter, Loading, Table, Title} from "../../components";
import {useFETCH, useFilter} from "../../APIs/useMyAPI";
import {Link, useParams} from "react-router-dom";
import {Col, Row} from "../../Grid-system";

const Reportscenters = () => {
    const {id} = useParams();
    const {filter} = useFilter();
    const {data, isLoading} = useFETCH(
        `admin/cost-centers/${id}/report${
            filter.get("page") ||
            filter.get("start_date") ||
            filter.get("date") ||
            filter.get("end_date")
                ? "?"
                : ""
        }${filter.get("page") ? "&page=" + filter.get("page") : ""}${
            filter.get("end_date") ? "&end_date=" + filter.get("end_date") : ""
        }${
            filter.get("date") && !filter.get("start_date")
                ? "&start_date=" + filter.get("date")
                : ""
        }${
            filter.get("start_date") ? "&start_date=" + filter.get("start_date") : ""
        }`
    );
    const {data: centersData} = useFETCH(`admin/cost-centers/${id}`);
    const {printClick} = useFETCH(`admin/cost-centers`);
    return (
        <div>
            {isLoading ? <Loading/> : ""}
            <Title title={` ${centersData?.data.data.project_name} > التقارير `}/>
            <div className="flex gap-3 my-2">
                <div
                    className="px-4 py-2  rounded-3xl bg-Green text-white cursor-pointer"
                    onClick={() =>
                        printClick(
                            `/${id}/report/export/excel${
                                filter.get("start_date") ||
                                filter.get("date") ||
                                filter.get("end_date")
                                    ? "?"
                                    : ""
                            }${
                                filter.get("end_date")
                                    ? "&end_date=" + filter.get("end_date")
                                    : ""
                            }${
                                filter.get("date") && !filter.get("start_date")
                                    ? "&start_date=" + filter.get("date")
                                    : ""
                            }${
                                filter.get("start_date")
                                    ? "&start_date=" + filter.get("start_date")
                                    : ""
                            }`,
                            "excel"
                        )
                    }
                >
                    طباعة التقارير excel
                </div>
                <div
                    className="px-4 py-2  rounded-3xl bg-Green text-white cursor-pointer"
                    onClick={() =>
                        printClick(
                            `/${id}/report/export/pdf${
                                filter.get("start_date") ||
                                filter.get("date") ||
                                filter.get("end_date")
                                    ? "?"
                                    : ""
                            }${
                                filter.get("end_date")
                                    ? "&end_date=" + filter.get("end_date")
                                    : ""
                            }${
                                filter.get("date") && !filter.get("start_date")
                                    ? "&start_date=" + filter.get("date")
                                    : ""
                            }${
                                filter.get("start_date")
                                    ? "&start_date=" + filter.get("start_date")
                                    : ""
                            }`,
                            "pdf"
                        )
                    }
                >
                    طباعة التقارير pdf
                </div>
                {/*          it not completed it needs to set its logic in the backend */}

                {/*  <Link*/}
                {/*  to={`/calendar/cost-centers/${id}`} */}
                {/*  className="px-4 py-2 rounded-3xl bg-Green text-white cursor-pointer"*/}
                {/*  onClick={() => {*/}
                {/*  }}*/}
                {/*>*/}
                {/*  عرض التقويم*/}
                {/*</Link>*/}
            </div>
            <Filter/>
            <Row>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green"> التكلفة الاجمالية :</div>
                    <div>{data?.data.total.cost}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        الساعات الأساسية الإجمالية :
                    </div>
                    <div>{data?.data.total.main_hours}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        الساعات الأضافية الإجمالية :
                    </div>
                    <div>{data?.data.total.extra_hours}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green"> الساعات الإجمالية :</div>
                    <div>{data?.data.total.hours}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        تكلفة الساعات الإضافية الإجمالية :
                    </div>
                    <div>{data?.data.total.extra_hours_cost}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        تكلفة الساعات الأساسية الإجمالية :
                    </div>
                    <div>{data?.data.total.main_hours_cost}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        تكلفة الساعات الإدارية الإجمالية :
                    </div>
                    <div>{data?.data.total.administrative_hours_cost}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">قيمة العقد :</div>
                    <div>{data?.data.total.contract_value}</div>
                </Col>
                <Col xs={6} md={4} className="flex gap-1">
                    <div className="font-semibold text-Green">
                        نسبة التكلفة الإجمالية من قيمة العقد :
                    </div>
                    <div>{data?.data.total.contract_percentage}</div>
                </Col>
            </Row>
            <Table
                pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
                thead={[
                    "ساعات أساسية ",
                    "ساعات إضافية",
                    "كلفة الساعات اساسية",
                    "كلفة الساعات إضافي",
                    "الكلفة الإجمالية",
                    "تكلفة الساعات الإدارية",
                    "التاريخ",
                ]}
                tbody={[
                    "main_hours",
                    "extra_hours",
                    "main_hours_cost",
                    "extra_hours_cost",
                    "total_hours_cost",
                    "administrative_hours_cost",
                    "date",
                ]}
                reportCenter={id}
                tdata={data?.data.data.data}
            />
            <Add link="/sections/add"/>
        </div>
    );
};

export default Reportscenters;
