import React, { useEffect, useState } from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();
  const { filter, handleParamsClick, searchParams } = useFilter();
  const { data, isLoading } = useFETCH(
    `s-admin/section/cost-centers${
      filter.get("page")
        ? searchParams.get("search")
          ? `?page=${filter.get("page")}&search=${searchParams.get("search")}`
          : `?page=${filter.get("page")}`
        : searchParams.get("search")
        ? `?search=${searchParams.get("search")}`
        : ""
    }`
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      searchParams.delete("search");
      handleParamsClick("search", value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <div className="flex gap-2 justify-between">
        <Title title="قسم لاختبار الفلترة حسب القسم" />
        <FaArrowLeft
          onClick={() => navigate("/")}
          className="p-3 bg-Green rounded-full cursor-pointer"
          color="white"
          size={50}
        />
      </div>
      <input
        type="text"
        value={searchTerm}
        placeholder="ابحث عن مركز"
        className="p-3 border border-gray-400 w-1/2 max-md:w-full rounded-lg"
        onChange={handleChange}
      />
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={["اسم المركز", "رقم العقد", "قيمة العقد"]}
        tbody={["project_name", "contract_number", "contract_value"]}
        tdata={data?.data.data.data}
        links={[{ name: "عرض عمال المركز", link: "center/" }]}
      />
    </div>
  );
};

export default Admin;
