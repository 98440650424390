import React, { useEffect, useState } from "react";
import { useFETCH, usePOST } from "../APIs/useMyAPI";
import Loading from "./Loading/Loading";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";

const PopUpAddWorker = ({ setIsOpen }) => {
  const { centerId } = useParams();
  const { setFormData, formData, loading, handleSubmit, successfulPost } =
    usePOST({
      worker_id: "",
    });

  const { data, isLoading } = useFETCH("s-admin/workers");

  useEffect(() => {
    if (successfulPost) setIsOpen(false);
  }, [successfulPost]);

  return (
    <div className="flex justify-center items-center w-full h-full absolute top-0 left-0">
      {(loading || isLoading) && <Loading />}
      <div
        className="bg-black/30 absolute w-full h-full top-0 left-0 z-10"
        onClick={() => setIsOpen(false)}
      />
      <div className="flex flex-col gap-4 bg-white rounded-lg border-gray-500 min-h-2/3 overflow-auto z-20 p-3">
        <IoCloseCircleSharp
          size={40}
          color="red"
          cursor="pointer"
          onClick={() => setIsOpen(false)}
        />
        <div className="flex gap-3 flex-col items-center">
          <button
            onClick={() =>
              handleSubmit(`s-admin/section/cost-centers/${centerId}/workers`)
            }
            className="w-full p-2 text-lg font-semibold bg-gray-300 text-gray-700"
          >
            إضافة
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpAddWorker;
