import {useFilter} from "../../APIs/useMyAPI";
import {Col, Row} from "../../Grid-system";
import {useState} from "react";

function WorkersFilter() {
    const {filter, setFilter} = useFilter();
    const [dateRange, setDateRange] = useState(null);

    const handelDateChange = (e) => {
        setFilter({
            check_by: filter.get("check_by"),
            date: e.target.value,
            page: filter.get("page") ? filter.get("page") : "1",
            ...(filter.get("section_id") && {section_id: filter.get("section_id")}),
            ...(filter.get("presented") && {presented: filter.get("presented")}),
        });
    }
    return (
        <Row className="" justify={"right"}>
            <Col md={2} className={"flex justify-start"}>
                <div className="border border-Main flex space-x-2 p-2 rounded-xl max-sm:w-full">
                    <select
                        value={filter.get("check_by")}
                        onChange={(e) => {
                            setFilter({
                                check_by: e.target.value,
                                page: filter.get("page") ? filter.get("page") : "1",
                                ...(filter.get("date") && {date: filter.get("date")}),
                                ...(filter.get("section_id") && {section_id: filter.get("section_id")}),
                                ...(filter.get("presented") && {presented: filter.get("presented")}),
                            });
                        }}
                        className="outline-none text-center"
                    >
                        <option value="" onClick={() => setFilter({})}></option>
                        <option className="text-black" value="day">
                            يوم
                        </option>
                        <option className="text-black" value="week">
                            اسبوع
                        </option>
                        <option className="text-black" value="month">
                            شهر
                        </option>
                    </select>
                </div>
            </Col>

            {filter.get("check_by") && (
                <Col md={5} className={`flex   items-center gap-5 flex-wrap`}>
                    <div className=" border border-Secondary p-2 rounded-xl relative flex items-center justify-center">
            <span className="absolute -top-3 bg-white px-2 left-3">
              اختر التاريخ
            </span>
                        <input
                            type="date"
                            value={filter.get("date")}
                            onChange={(e) => {
                                handelDateChange(e);
                            }}
                        />
                    </div>
                </Col>
            )}
        </Row>
    );
}

export default WorkersFilter;
