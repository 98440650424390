import React from "react";
import { Link } from "react-router-dom";

const Add = ({ link, onClick }) => {
  return (
    <>
      {link ? (
        <Link to={link || ""}>
          <div
            className={`flex z-10 justify-center fixed bottom-10 left-5 font-bold text-5xl text-white cursor-pointer w-14 h-14 rounded-full bg-Green`}
          >
            +
          </div>
        </Link>
      ) : (
        <button
          className={`flex z-10 justify-center fixed bottom-10 left-5 font-bold text-5xl text-white cursor-pointer w-14 h-14 rounded-full bg-Green`}
          onClick={onClick}
        >
          +
        </button>
      )}
    </>
  );
};

export default Add;
