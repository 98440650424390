import {useContextHook} from "../../Context/ContextOPen";
import "./SideBar.css";
import {NavLink} from "react-router-dom";
import logo from "../../images/siab.png";
import {BsSignIntersectionSideFill} from "react-icons/bs";
import {RiAdminFill} from "react-icons/ri";
import {MdBusinessCenter} from "react-icons/md";
import {GrUserWorker} from "react-icons/gr";
import {MdMonetizationOn} from "react-icons/md";
import {RiLockPasswordFill} from "react-icons/ri";

function SideBar() {
    const {openMenu} = useContextHook();
    return (
        <div
            className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
                openMenu
                    ? " max-sm:translate-x-0 max-sm:w-[100%] "
                    : " max-sm:-translate-x-full"
            } sidebar h-[100vh] bg-Green max-sm:h-[100%] sticky max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-30`}
        >
            <div className="px-3">
                <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
                    <li className="">
                        <img
                            src={logo}
                            alt=""
                            className="mx-auto w-[200px] rounded-xl max-sm:pt-5"
                        />
                    </li>
                    {!localStorage.getItem("role") ? (
                        <>
                            <li className=" ">
                                <NavLink
                                    to={"/sections"}
                                    className="text-xl py-2  rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F] flex justify-between items-center px-8"
                                >
                                    <span>الأقسام</span>
                                    <BsSignIntersectionSideFill size={30} color="#0909"/>
                                </NavLink>
                            </li>

                            <li className=" ">
                                <NavLink
                                    to={"/department-managers"}
                                    className="text-xl py-2  rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F] flex justify-between items-center px-8"
                                >
                                    <span>مدراء الأقسام</span>
                                    <RiAdminFill size={30} color="#0909"/>
                                </NavLink>
                            </li>
                            <li className=" ">
                                <NavLink
                                    to={"/cost-centers"}
                                    className="text-xl py-2  rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F] flex justify-between items-center px-8"
                                >
                                    <span>مراكز التكلفة</span>
                                    <MdBusinessCenter size={30} color="#0909"/>
                                </NavLink>
                            </li>
                            <li className=" ">
                                <NavLink
                                    to={"/workers"}
                                    className="text-xl py-2  rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F] flex justify-between items-center px-8"
                                >
                                    <span>العمال</span>
                                    <GrUserWorker size={30} color="#0909"/>
                                </NavLink>
                            </li>

                        </>
                    ) : (
                        <li className=" ">
                            <NavLink
                                to={"/admin"}
                                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
                            >
                                مدير القسم
                            </NavLink>
                        </li>
                    )}

                    <li className="pb-10">
                        <NavLink
                            to={"/Change-Password"}
                            className="text-xl py-2 rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F] flex justify-between items-center px-8"
                        >
                            <span>تغيير كلمة السر</span>
                            <RiLockPasswordFill size={30} color="#0909"/>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SideBar;
