import { useEffect } from "react";

const usePreventWheel = () => {
    useEffect(() => {
      // Define the event handler for the 'wheel' event
      const handleWheel = (event) => {
        // Check if the event target is an input element of type 'number' and if it's focused
        if (event.target.type === 'number' && document.activeElement === event.target) {
          // Prevent the default behavior of the wheel event (i.e., scrolling)
          event.preventDefault();
        }
      };
  
      // Add the event listener to the document for the 'wheel' event
      document.addEventListener('wheel', handleWheel, { passive: false });
  
      // Cleanup the event listener when the component is unmounted or when the effect dependencies change
      return () => {
        document.removeEventListener('wheel', handleWheel);
      };
    }, []); // Empty dependency array means this effect runs once when the component mounts and cleans up when it unmounts
  };
  
  export default usePreventWheel;
  