import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { Loading, Table, Title } from "../../components";
import PopUpAddHours from "../../components/PopUpAddHours";
import PopUpAddWorker from "../../components/PopUpAddWorker";

const CenterWorkers = () => {
  let filterDate = sessionStorage.getItem("filter_date");
  let date = new Date();
  const [addHours, setAddHours] = useState(false);
  const [workersIds, setWorkersIds] = useState([]);
  const {centerId}=useParams();

  const navigate = useNavigate();
  const [popUpAddHoursOpen, setPopUpAddHoursOpen] = useState(["", false]);
  const [popUpAddWorkerOpen, setPopUpAddWorkerOpen] = useState(false);
  const { filter, handleParamsClick, searchParams, setFilter } = useFilter();
  const [temp, setTemp] = useState(0);

  const { data, isLoading } = useFETCH(
    `s-admin/section/workers?${
      filterDate
        ? `date=${filterDate}&temp=${temp}&`
        : filter.get("date")
        ? `date=${filter.get("date")}&temp=${temp}&`
        : `date=${date.toISOString().split("T")[0]}&temp=${temp}&`
    }${
      filter.get("page")
        ? searchParams.get("search")
          ? `page=${filter.get("page")}&search=${searchParams.get("search")}`
          : `page=${filter.get("page")}`
        : searchParams.get("search")
        ? `search=${searchParams.get("search")}`
        : ""
    }`
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const handleFilterByDate = (event) => {
    filter.set("date", event.target.value);
    setFilter((prev) => ({
      ...prev,
      date: filter.get("date"),
    }));
    sessionStorage.setItem("filter_date", event.target.value);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      searchParams.delete("search");
      handleParamsClick("search", value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      {popUpAddWorkerOpen && (
        <PopUpAddWorker setIsOpen={setPopUpAddWorkerOpen} />
      )}
      {popUpAddHoursOpen[1] && (
        <PopUpAddHours
          filterDate={
            sessionStorage.getItem("filter_date")
              ? sessionStorage.getItem("filter_date")
              : filter.get("date")
          }
          ids={workersIds}
          id={popUpAddHoursOpen[0]}
          setIsOpen={setPopUpAddHoursOpen}
          setTemp={setTemp}
          centerId={centerId}
        />
      )}
      <div className="flex gap-2 justify-between">
        <Title
          title={`عمال مركز ${data?.data?.cost_center?.project_name || ""}`}
        />
        <FaArrowLeft
          onClick={() => navigate("/admin")}
          className="p-3 bg-Green rounded-full cursor-pointer"
          color="white"
          size={50}
        />
      </div>
      <div className="flex justify-between gap-2 mt-5 flex-wrap">
        <input
          type="text"
          value={searchTerm}
          placeholder="ابحث عن عامل"
          className="p-3 border border-gray-400 w-[40%] max-md:w-full rounded-lg"
          onChange={handleChange}
        />
        <input
          type="date"
          placeholder="فلترة حسب التاريخ"
          onChange={handleFilterByDate}
          className="p-3 border border-gray-400 w-[40%] max-md:w-full rounded-lg"
          defaultValue={
            sessionStorage.getItem("filter_date")
              ? sessionStorage.getItem("filter_date")
              : date.toISOString().split("T")[0]
          }
        />
      </div>
      <div className="flex gap-2 items-center mt-5">
        <div className="w-5 h-5 rounded-full bg-green-400" />
        <h2 className="font-semibold text-lg">
          العامل لديه ساعات في التاريخ المختار
        </h2>
      </div>
      {workersIds.length > 0 && (
        <button
          onClick={() => setPopUpAddHoursOpen(["", true])}
          className=" mt-5 text-lg rounded-lg text-white bg-Green px-6 py-2 font-semibold"
        >
          إضافة ساعات للعمال المحددين
        </button>
      )}
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[
          "الرقم",
          "اسم العامل",
          "عدد الساعات في الشهر",
          "تكلفة الساعة الإنتاجية",
          "التاريخ",
        ]}
        tbody={["id", "name", "month_hours", "hour_cost", "created_at"]}
        tdata={data?.data?.data?.data}
        links={[
          { name: "عرض تقرير العامل", link: "workers/" },
          {
            name: "إضافة ساعات",
            onClick: (id) => setPopUpAddHoursOpen([id, true]),
          },
        ]}
        isWorkers={true}
        addHoursCheck={true}
        addHours={addHours}
        setAddHours={setAddHours}
        workersIds={workersIds}
        setWorkersIds={setWorkersIds}
      />
    </div>
  );
};

export default CenterWorkers;
