import React, { useEffect, useState } from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const DepartmentManagers = () => {
  const { filter } = useFilter();
  const [temp, setTemp] = useState(0);

  const { data, deleteItem, isLoading, successfulDelete } = useFETCH(
    `admin/sections/admins${
      filter.get("page")
        ? "?page=" + filter.get("page") + `&temp=${temp}`
        : `?temp=${temp}`
    }`,
    "admin/sections/admins"
  );

  useEffect(() => {
    if (successfulDelete) setTemp((prev) => prev + 1);
  }, [successfulDelete]);

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="مدراء الأقسام" />
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={["اسم المستخدم ", "اسم ", "اسم القسم"]}
        tbody={["username", "name", "section"]}
        tdata={data?.data.data.data}
        idDel={deleteItem}
        links={[{ name: "تعديل", link: "/department-managers/" }]}
      />
      <Add link="/department-managers/add" />
    </div>
  );
};

export default DepartmentManagers;
