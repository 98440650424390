import "./Calendarsec.css";
import "react-calendar/dist/Calendar.css";
import { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { useParams, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import {
  useNavigate,
} from "react-router-dom";
// import { CiLineHeight } from "react-icons/ci";
// import { isEqual } from 'date-fns';

function Calendarsec() {
  let datesArray = [];
  const formatDateToYMD = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getLastDayOfMonth = (year, month) => {
    // const lastDay = new Date(year, month, 0).getDate() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const lastDayFormatted = `${year}-${month.toString().padStart(2, "0")}-${lastDay.toString().padStart(2, "0")}`;
    return lastDayFormatted;
  };
  const handelChangeMonth = (startDate) => {
    const inputDate = new Date(startDate.activeStartDate);
    const formattedDate = formatDateToYMD(inputDate);
    console.log(formattedDate);
    // console.log(data)
    // if (data && data.data && data.data.length > 0) {
    //   const firstItem = data.data[0];
    //   console.log(firstItem.worked_at); 
    // }

    const [year, month] = formattedDate.split("-").map(Number);
    const lastDayOfMonth = getLastDayOfMonth(year, month);
    console.log(lastDayOfMonth);
    setSearchParams({ start_date: formattedDate, end_date: lastDayOfMonth });
  };
  const { filter, setFilter } = useFilter();
  let currentDate = null;
  if (!filter.get('start_date')) {
    currentDate = new Date();
  }
  else
    currentDate = new Date(filter.get('start_date'))
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const firstDay = "01";
  const lastDay = new Date(year, month, 0).getDate();

  const formattedFirstDate = `${year}-${month
    .toString()
    .padStart(2, "0")}-${firstDay}`;
  const formattedLastDate = `${year}-${month
    .toString()
    .padStart(2, "0")}-${lastDay.toString().padStart(2, "0")}`;

  const { id, type } = useParams();

  const [searchParams, setSearchParams] = useSearchParams({
    start_date: formattedFirstDate,
    end_date: formattedLastDate,
    claneder_mode: 1,
  });

  const queryString = searchParams.toString();

  const { data, isLoading } = useFETCH(
    `admin/${type}/${id}/report?${queryString}`,
    `admin/${type}/${id}/report`
  );
  const naviget = useNavigate();

  return (


    <div className="cland">
      {isLoading ? <Loading /> : ""}
      <h1 className="text-center bold">تقويم العمل</h1>
      <div className="calendar-container">
        <Calendar
          value={currentDate}
          onClickDay={(date) => {
            if (type == 'workers')
              naviget(`/${type}/reports/${id}?start_date=${encodeURIComponent(formatDateToYMD(date))}&calender=1`);
            else {
              let param = type == 'sections' ? 'section_id' : 'cost_center_id';
              naviget(`/workers?date=${encodeURIComponent(formatDateToYMD(date))}&${param}=${id}&calender=1`)
            }
          }}
          className="rtl"
          onActiveStartDateChange={handelChangeMonth}
          tileContent={({ date }) => {
            date = new Date(date);
            const dayOfMonth = date.getDate();

            let is_completed = data?.data?.data[dayOfMonth - 1]?.is_completed;

            const colorClass = is_completed ? "green" : "red";
            const iconClass = is_completed ? "fa-solid fa-check" : "fa-solid fa-xmark";

            return (
              <div className={colorClass}>
                <span>
                  <i className={iconClass}></i>
                </span>
              </div>
            );
          }}
        />

      </div>
      <p className="text-center">
        {/* <span className='bold'>Selected Date:</span> {"date"} */}
      </p>
    </div>
  );
}

export default Calendarsec;

