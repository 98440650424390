import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { Add, Delete, Loading, Table, Title } from "../../components";
import PopUpEditHours from "../../components/PopUpEditHours";
import { FaArrowLeft } from "react-icons/fa";

const WorkerReport = () => {
  const navigate = useNavigate();
  const [isPopUpEditHoursOpen, setIsPopUpEditHoursOpen] = useState(["", false]);
  const [temp, setTemp] = useState(0);

  const { filter } = useFilter();
  const { workerId, centerId } = useParams();
  const { data, isLoading, deleteItem, successfulDelete } = useFETCH(
    `s-admin/section/workers/${workerId}/report?page=${filter.get(
      "page"
    )}&temp=${temp}`,
    `s-admin/section/cost-centers/workers/hours`
  );

  const { deleteItem: deleteWorker } = useFETCH(
    "",
    `s-admin/section/cost-centers/${centerId}/workers`
  );

  useEffect(() => {
    if (successfulDelete) {
      setTemp((prev) => prev + 1);
    }
  }, [successfulDelete]);

  return (
    <div>
      {isPopUpEditHoursOpen[1] && (
        <PopUpEditHours
          setIsOpen={setIsPopUpEditHoursOpen}
          id={isPopUpEditHoursOpen[0]}
          setTemp={setTemp}
        />
      )}
      {isLoading ? <Loading /> : ""}
      <div className="flex justify-between gap-2">
        <Title title={` العامل ${data?.data?.worker?.name}`} />
        <FaArrowLeft
          onClick={() => navigate(`/admin/center/${centerId}`)}
          className="p-3 bg-Green rounded-full cursor-pointer"
          color="white"
          size={50}
        />
      </div>
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        idDel={deleteItem}
        thead={[
          "التاريخ",
          "ساعات العمل الأساسية",
          "ساعات العمل الإضافية",
          "ساعات العمل الكلية",
          "الكلفة",
        ]}
        tbody={[
          "worked_at",
          "main_hours",
          "extra_hours",
          "total_hours",
          "total_cost",
        ]}
        tdata={data?.data.data.data}
        links={[
          {
            name: "تعديل",
            onClick: (id) => setIsPopUpEditHoursOpen([id, true]),
          },
        ]}
      />
      <Delete
        onClick={() => {
          deleteWorker({ id: workerId });
        }}
      >
        <button className="mt-5 w-fit px-6 py-3 text-lg font-semibold text-slate-700 bg-slate-300 rounded-xl">
          حذف العامل
        </button>
      </Delete>
    </div>
  );
};

export default WorkerReport;
